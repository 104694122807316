import { useEffect, useRef, useState } from 'react';
import {useLocation} from "react-use";
import {useSbCalls} from "../lib/sendbird-calls";
import {useHistory, useRouteMatch} from "react-router-dom";
import storage from "../lib/storage";
import {AuthOption, SoundType} from "sendbird-calls";
import {toast} from "react-toastify";
import {ErrorMessage} from "../components/atoms/Toast";

export const useOnClickOutside = (handler: () => void) => {
  const ref = useRef(null);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
  return ref;
};

export const useLogin = () => {
  const { url } = useRouteMatch();
  const sbCalls = useSbCalls();
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);

  const authQuery = query.get('q');
  let authArgs: any = {};
  if (authQuery) {
    try {
      authArgs = JSON.parse(atob(authQuery));
    } catch(e) {}
  }

  const stored = storage.getItem('sbCalls');

  const APP_ID = authArgs.app_id || stored?.appId || process.env.REACT_APP_APP_ID || '';
  const USER_ID = authArgs.user_id || stored?.userId || process.env.REACT_APP_USER_ID || '';
  const ACCESS_TOKEN = authArgs.access_token || stored?.accessToken || process.env.REACT_APP_ACCESS_TOKEN || '';
  const IS_ACCESS_TOKEN_NEEDED = process.env.REACT_APP_IS_ACCESS_TOKEN_NEEDED === 'true';
  // const ROOM_ID = authArgs.room_id || '';

  const login = () => {

    const option: AuthOption = { userId: USER_ID };
    if (IS_ACCESS_TOKEN_NEEDED || authArgs.access_token) option.accessToken = ACCESS_TOKEN;
    sbCalls.init(APP_ID);
    sbCalls.addDirectCallSound(SoundType.DIALING, '/sounds/Dialing.mp3');
    sbCalls.addDirectCallSound(SoundType.RINGING, '/sounds/Ringing.mp3');
    sbCalls.addDirectCallSound(SoundType.RECONNECTING, '/sounds/Reconnecting.mp3');
    sbCalls.addDirectCallSound(SoundType.RECONNECTED, '/sounds/Reconnected.mp3');
    return sbCalls.auth(option)
      .then(user => {
        storage.setItem('sbCalls', { appId: APP_ID, userId: USER_ID, accessToken: ACCESS_TOKEN });
        // console.log('query.get(\'referrer\'): ', query.get('referrer'))
        // history.push( `${query.get('referrer') ? query.get('referrer') + `?room_id=${ROOM_ID}` : '/'}`);
        history.push( `${query.get('referrer') ? query.get('referrer') + 'direct-call/full-screen' : '/'}`);
      })
      .catch(error => {
        toast.error(<ErrorMessage message={`Check entered information and try again.`} />, { autoClose: 2000 });
      })
  }

  useEffect(() => {
    if(!sbCalls.isAuthenticated) {
      query.set('referrer', url);
    }
    // console.log(authArgs.app_id, sbCalls.isAuthenticated)
    // if (authArgs.app_id && !sbCalls.isAuthenticated) login();
  }, [sbCalls.isAuthenticated])

  return { login }
}
