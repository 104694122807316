import {
  useHistory,
  useRouteMatch,
  useLocation,
  Route,
  RouteProps,
} from 'react-router-dom';

import { useSbCalls } from 'lib/sendbird-calls';
import {useLogin} from "utils/hooks";
import {useEffect} from "react";
import Preloader from "components/atoms/Preloader";

const PrivateRoute = ({ component: Component, render = () => null, ...props }: RouteProps) => {
  const { isAuthenticated } = useSbCalls();
  const { login } = useLogin();

  useEffect(() => {
    if(!isAuthenticated) login()
  }, [isAuthenticated])

  return <Route {...props} render={renderProps => {
    // return <Preloader />
    if (!isAuthenticated) {
      // query.set('referrer', url);
      // console.log('isAuthenticated: ', isAuthenticated)
      // console.log('query.toString(): ', query.toString())
      // history.push(`/login?${query.toString()}`);
      // login(query)
      // debugger
      return <Preloader />;
    }
    if (Component) return <Component {...renderProps} />;
    return render(renderProps);
  }} />
}

export default PrivateRoute;
