import React from 'react';
import styled from "styled-components";

const Loader = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #E9EFFC;
`;

const Elements = styled.div`
  border: 3px solid transparent;
  border-top-color: #2164E8;
  border-right-color: #2164E8;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`

const Outer = styled(Elements)`
  width: 3.5em;
  height: 3.5em;
  margin-left: -1.75em;
  margin-top: -1.75em;
  animation: spin 2s linear infinite;
`
const Middle = styled(Elements)`
  width: 2.1em;
  height: 2.1em;
  margin-left: -1.05em;
  margin-top: -1.05em;
  animation: spin 1.75s linear reverse infinite;
`
const Inner = styled(Elements)`
  width: 0.8em;
  height: 0.8em;
  margin-left: -0.4em;
  margin-top: -0.4em;
  animation: spin 1.5s linear infinite;
`

//.loader {
//   position: relative;
// }
//
// .outer,
// .middle,
// .inner {
//   border: 3px solid transparent;
//   border-top-color: #3cefff;
//   border-right-color: #3cefff;
//   border-radius: 50%;
//   position: absolute;
//   top: 50%;
//   left: 50%;
// }
//
// .outer {
//   width: 3.5em;
//   height: 3.5em;
//   margin-left: -1.75em;
//   margin-top: -1.75em;
//   animation: spin 2s linear infinite;
// }
//
// .middle {
//   width: 2.1em;
//   height: 2.1em;
//   margin-left: -1.05em;
//   margin-top: -1.05em;
//   animation: spin 1.75s linear reverse infinite;
// }
//
// .inner {
//   width: 0.8em;
//   height: 0.8em;
//   margin-left: -0.4em;
//   margin-top: -0.4em;
//   animation: spin 1.5s linear infinite;
// }
//
// @keyframes spin {
//   to {
//     transform: rotate(360deg);
//   }
// }

const Preloader: React.FC<any> = ({ children, ...props }) => (
  <Loader>
    <Outer />
    <Middle />
    <Inner />
  </Loader>

);


export default Preloader;